<template>
  <div class="mb-4 mt-3">
    <div class="text-left">
      <!-- <span class="number-title-evaluative-agent">02. </span> -->
      <span class="title-evaluative-agent">{{
        $getVisibleNames("manual.test.agente_evaluativo", false, "Tipo")
      }}</span>
    </div>
    <div class="container-evaluative-agent mt-3">
      <template v-if="matter_evaluation != null && show_by_matter_evaluation">
        <div
          v-for="test_type in test_types"
          :key="'test-type-' + test_type.id"
          class="container-card mr-3 mb-3"
        >
          <div class="secundary-cell">
            <template
              v-if="matter_evaluation.evaluative_agents.includes(test_type.id)"
              >X</template
            >
          </div>
          <div class="primary-cell">{{ test_type.name.toUpperCase() }}</div>
        </div>
      </template>
      <template v-else-if="evaluation_id == null && test">
        <div
          v-for="test_type in test_types"
          :key="'test-type-' + test_type.id"
          class="container-card mr-3 mb-3"
        >
          <div class="secundary-cell">
            <template v-if="test.evaluative_agent == test_type.id">X</template>
          </div>
          <div class="primary-cell">{{ test_type.name.toUpperCase() }}</div>
        </div>
      </template>
      <template v-else>
        <div
          v-for="test_type in test_types"
          :key="'test-type-' + test_type.id"
          class="container-card mr-3 mb-3"
        >
          <div class="secundary-cell">
            <template v-if="evaluative_agents_by_tests.includes(test_type.id)"
              >X</template
            >
          </div>
          <div class="primary-cell">{{ test_type.name.toUpperCase() }}</div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";

export default {
  name: "EvaluativeAgent",
  props: {
    instrument_id: {
      type: Number,
    },
    evaluation_id: {
      type: Number,
    },
    matter_evaluation: {
      type: Object,
      default: null,
    },
    show_by_matter_evaluation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      tests: names.NEW_TESTS,
      test_types: names.NEW_TEST_TYPES,
    }),
    test() {
      return this.tests.find((x) => x.id == this.instrument_id);
    },
    evaluative_agents_by_tests() {
      if (!this.evaluation_id) return [];
      let evaluative_agents_ids = this.tests
        .filter((x) => x.evaluation == this.evaluation_id)
        .map((x) => x.evaluative_agent);
      return [...new Set(evaluative_agents_ids)];
    },
  },
  methods: {},
};
</script>

<style scoped>
.number-title-evaluative-agent {
  font-size: 32px;
  color: rgb(255, 153, 0);
}
.title-evaluative-agent {
  font-size: 32px;
}
.container-evaluative-agent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  font-size: 10pt;
}
.container-card,
.container-heteroevaluation,
.container-co-evaluation,
.container-auto-evaluation {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.primary-cell {
  padding: 7px;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: var(--kl-menu-color) !important;
  color: white;
  font-weight: bold;
  text-align: start;
}
.secundary-cell {
  padding: 7px;
  min-width: 50px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* font-weight: 500; */
}
</style>